<template>
    <div style="min-height: 100%;background-color: #EEEEEC;">
        <div class="header">
            <i @click="go('/')" class="el-icon-arrow-left"></i>
            <span>光学估重</span>
            <span>离线</span>
        </div>
        <div>
            <img style="width: 100%;" src="@/assets/img/组 6.png" alt="">
        </div>
        <el-card style="margin:10px;text-align: left;padding: 0;" v-show="!$route.query.fail">
            <van-cell-group style="padding: 10px;">
                <div data-v-28a5ce7f="" class="van-cell">
                    <div data-v-28a5ce7f="" class="van-cell__title">
                        <span class="btn-label">体长</span>
                        <!-- <div data-v-28a5ce7f="" class="van-cell__label">描述信息</div> -->
                    </div>
                    <div data-v-28a5ce7f="" class="van-cell__value">
                        <div data-v-28a5ce7f="">60cm</div>
                    </div>
                </div>
                <div data-v-28a5ce7f="" class="van-cell">
                    <div data-v-28a5ce7f="" class="van-cell__title">
                        <span class="btn-label">胸围</span>
                        <!-- <div data-v-28a5ce7f="" class="van-cell__label">描述信息</div> -->
                    </div>
                    <div data-v-28a5ce7f="" class="van-cell__value">
                        <div data-v-28a5ce7f="">65CM</div>
                    </div>
                </div>
                <div data-v-28a5ce7f="" class="van-cell">
                    <div data-v-28a5ce7f="" class="van-cell__title">
                        <span class="btn-label">猪重</span>
                        <!-- <div data-v-28a5ce7f="" class="van-cell__label">描述信息</div> -->
                    </div>
                    <div data-v-28a5ce7f="" class="van-cell__value">
                        <div data-v-28a5ce7f="" style="font-size: 20px;color: red;">25KG</div>
                    </div>
                </div>
                <!-- <van-cell title="验标方式" value="智能点数" /> -->
            </van-cell-group>
        </el-card>
        <el-card style="margin:10px;text-align: left;padding: 0;" v-show="$route.query.fail">
            <van-cell-group style="padding: 10px;">
                <div class="van-cell">
                    <div class="van-cell__title">
                        <span class="btn-label">体长</span>
                        <!-- <div data-v-28a5ce7f="" class="van-cell__label">描述信息</div> -->
                    </div>
                    <div data-v-28a5ce7f="" class="van-cell__value">
                        <div data-v-28a5ce7f="">60cm</div>
                    </div>
                </div>
                <div data-v-28a5ce7f="" class="van-cell">
                    <div data-v-28a5ce7f="" class="van-cell__title">
                        <span class="btn-label">胸围</span>
                        <!-- <div data-v-28a5ce7f="" class="van-cell__label">描述信息</div> -->
                    </div>
                    <div data-v-28a5ce7f="" class="van-cell__value">
                        <div data-v-28a5ce7f="">55CM</div>
                    </div>
                </div>
                <div data-v-28a5ce7f="" class="van-cell">
                    <div data-v-28a5ce7f="" class="van-cell__title">
                        <span class="btn-label">猪重</span>
                        <!-- <div data-v-28a5ce7f="" class="van-cell__label">描述信息</div> -->
                    </div>
                    <div data-v-28a5ce7f="" class="van-cell__value">
                        <div data-v-28a5ce7f="" style="font-size: 20px;color: red;">23KG</div>
                    </div>
                </div>
                <!-- <van-cell title="验标方式" value="智能点数" /> -->
            </van-cell-group>
            <!-- <div class="btn-box">
                <div>
                    <i class="fa fa-plane"></i>
                    离线采集
                </div>
                <div @click="go('/scanFace2')">
                    <i class="fa fa-camera"></i>
                    在线采集
                </div>
            </div> -->
        </el-card>
    </div>
</template>
<script>
export default{
    data() {
        return {
            pigs:[{
                face:require('@/assets/img/pig_scan.png'),
                id:'13581248',
                date:'2024-03-08 14:35'
            },
            {
                face:require('@/assets/img/pig_scan.png'),
                id:'13581248',
                date:'2024-03-08 14:35'
            },
            {
                face:require('@/assets/img/pig_scan.png'),
                id:'13581248',
                date:'2024-03-08 14:35'
            },
            {
                face:require('@/assets/img/pig_scan.png'),
                id:'13581248',
                date:'2024-03-08 14:35'
            },
            {
                face:require('@/assets/img/pig_scan.png'),
                id:'13581248',
                date:'2024-03-08 14:35'
            }]
        }
    },
}
</script>
<style lang="stylus" scoped>
.header{
    background-color: #04C694;
    height: 2.5rem;
    line-height: 2.5rem;
    color: #fff;
    display flex;
    justify-content space-between;
    align-items center
    box-sizing border-box;
    padding 0 20px;
    i{
        font-size 26px;
        font-weight 600
    }
}

.pig-container{
    padding: 0 10px
}

.pig-box{
    margin 10px 0;
    position relative;
    background-color #fff;
    padding-bottom 10px;
    img{
        width 100%;
    }

    .info{
        text-align left ;
        padding 0 10px; 
        .id{
            float right;
            color #029A64;
        }
        .time{
            font-size 14px;
            margin-top 5px;
        }
    }
}

.btn{
    position fixed;
    z-index 2;
    bottom 50px;
    background-color #04C694;
    color #fff;
    width 70px;
    height 70px;
    line-height 70px;
    border-radius 50%;
    left 50%
    transform translateX(-50%);
    // cursor pointer;
}

.card{
    background-color #fff
    margin 10px;
    border-radius 5px;
    .title{
        font-size 18px;
        border-left 3px solid #04C694;
        text-align left;
        text-indent 5px;
        margin 10px 10px;
    }
    .comment{
        font-size 14px;
        margin-bottom 10px;
        text-align left;
        text-indent 20px;
    }
    .next{
        margin-top 10px;
        color #04C694;
        i{
            font-size 16px;
            font-weight 600;
        }
    }
}

.corner{
    position absolute;
    right 5px;
    top 5px;
    font-size 20px;
    z-index 1;
    color #fff;
}

.btn-label{
    padding: 5px 20px;
    // font-weight 600;
    font-size 16px
    background-color: #04c694;
    color #fff;
}
</style>

<style>

</style>
